// import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, {useState, useEffect} from "react"
import Login from './login'
import NewProduct from './products/new'
import UserForm from './user'
import { logout, getUser, request } from '../lib/api'
import { navigate, Link } from "gatsby"
import Dropdown from 'react-bootstrap/Dropdown'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'

function Header ({ siteTitle }) {
  const [modalShow, setModalShow] = useState(false)
  const [userModal, setUserModal] = useState(false)
  const [productModalShow, setProductModalShow] = useState(false)
  const [user, setUser] = useState(null)
  const [productList, setProductList] = useState([])


  useEffect(()=>{
    getUser((userData)=>{
      if (!user && userData) {
        setUser(userData)
      }
      if (!user && !userData && window.location.pathname !== "/") {
        navigate('/')
      }
    })
  })

  const search = (e) => {
    const text = e.target.value
    if (text.length <= 2) {
      return null
    }
    request('POST', '/wwd/products/search', {search: text}).then((res)=>{
      setProductList(res.results)
    })
  }

  const onLogout = () => {
    logout()
    window.location.href = '/'
  }

  return (
    <Navbar collapseOnSelect expand="lg" bg="primary" variant="light" style={{ backgroundColor: "#007bff", padding: "8px 10px" }}>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto">
          <Link to={"/"} aria-current="page" className="nav-link">Home</Link>
          {
            user && (
              <>
              <a aria-current="page" className="nav-link" onClick={()=>setUserModal(true)}>
                Add User
              </a>
              <a aria-current="page" className="nav-link" onClick={() => setProductModalShow(true)}>
                Add Product
              </a>

              <Link to={"/categories"} aria-current="page" className="nav-link">Categories</Link>
              <Link to={"/products"} aria-current="page" className="nav-link">Products</Link>

              <a aria-current="page" className="nav-link" onClick={onLogout}>Logout</a>
              </>
            )
          }
          <NewProduct onHide={()=>setProductModalShow(false)} show={productModalShow} />
          <UserForm onHide={()=>setUserModal(false)} show={userModal} />

        </Nav>

        {
          user && (
            <div className="navbar-nav">
              <div className="nav-item">
                <span style={{color: "white", padding: '5px', lineHeight: '2.4rem'}}>
                  <strong>Welcome {user.first_name}! </strong>
                </span>
              </div>

              <Dropdown className=" mr-auto">
                <Dropdown.Toggle variant="primary" id="dropdown-basic">
                  <form className="form-inline my-2 my-lg-0">
                    <input autoComplete="off" className="form-control mr-sm-2" id="product-search" 
                      type="search" placeholder="Search Product" aria-label="Search" 
                      onChange={(e)=>search(e)}/>
                  </form>
                </Dropdown.Toggle>
                {
                  productList.length > 0 &&
                  <Dropdown.Menu>
                    {
                      productList.map((p)=>(
                        <Dropdown.Item key={p._id} href={`/product/update?id=${p._id}`}>
                          {p.name}
                        </Dropdown.Item>
                      ))
                    }
                  </Dropdown.Menu>
                }
              </Dropdown>
            </div>
          )
        }
        {
          !user && (
            <div className="navbar-nav">
              <div>
                <a onClick={() => setModalShow(true)} className="nav-link">Login</a>
                <Login show={modalShow} onHide={() => setModalShow(false)} />
              </div>
            </div>
          )
        }
      </Navbar.Collapse>

      <Link to={"/"} aria-current="page" className="nav-link" style={{ padding: "5px 0" }}>
        <img src={'/logo.png'} alt="WWD" height="100px" width="75px" style={{ marginBottom: 0 }} />
      </Link>
    </Navbar>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
