import React from "react"
import PropTypes from "prop-types"
import Header from "./header"
import "./layout.css"

const Layout = ({ style={}, children }) => {
  return (
    <>
      <Header siteTitle={"Waste Water Depot CMS"} />
      <div className="container"
        style={{
          margin: `0 auto`,
          padding: `0 1.0875rem 1.45rem`,
          marginTop: '5%',
          ...style
        }}
      >
        {children}
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
