// const BaseUrl = 'https://api.a3i.app'
const BaseUrl = process.env.GATSBY_API_URL || 'http://localhost:8081'
const tokenKey = 'token'
const userKey = 'wwduser'

const request = (method, url, data) => {
  return fetch(`${BaseUrl}${url}` , {
    method: method,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  }).then((results) => {
    return results.json();
  })
}

const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
})


const requestWithHeaders = (method, url, data, headers={}) => {
  return fetch(`${url}` , {
    method: method,
    headers: {
      'Content-Type': 'application/json',
      ...headers
    },
    body: data
  })
}

const saveToken = (val) => {
  window.localStorage.setItem(tokenKey, val)
}

const saveUser = (user) => {
  if (user) {
    window.localStorage.setItem(userKey, JSON.stringify(user))
  } else {
    window.localStorage.setItem(userKey, user)
  }
}

const getUser = (fn) => {
  let data = window.localStorage.getItem(userKey)
  if (data) {
    fn(JSON.parse(data))
  } else {
    fn(null)
  }
}

const logout =  () => {
  saveToken(null)
  saveUser(null)
  return null
}

const login = (user, token)=>{
  saveToken(token)
  saveUser(user)
  return
}

export {
  request,
  login,
  logout,
  getUser,
  requestWithHeaders,
  toBase64
}