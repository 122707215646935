import React, { useState, useEffect, useRef } from "react"
import Alert from 'react-bootstrap/Alert'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { request, toBase64 } from '../../lib/api'

const NewProduct = (props) => {

  const formRef = useRef(null);
  const [ error, setError ] = useState(null)
  const [ file, setFile ] = useState(null)
  const [ categories, setCategories ] = useState(null)
  const [ prod, setProd ] = useState({
    category_id: ''
  })

  useEffect(()=>{
    formRef != null && formRef.current !== null && formRef.current.reset()
    if (categories == null ) {
      getCategories()
    }
  }, [])

  const getCategories = ()=>{
    request("GET", '/wwd/categories').then((res)=>{
      setCategories(res.results)
    }).catch((e)=>{
      console.log(e)
    })
  }

  const onChange = (e) => {
    setProd({
      ...prod,
      [e.target.name]: e.target.value
    })
  }

  const onToggle = (e) => {
    setProd({
      ...prod,
      active: !prod.active
    })
  }

  const onSave = async (e) => {
    e.preventDefault()
    if (file && file !== null && file !== undefined) {
      const { name, type, size } = file
      const base64 = await toBase64(file)
      prod.file = { name, type, size, base64 }
    }
    setError(null)
    request("POST", '/wwd/products/new', prod).then((res)=>{
      if (res && res.success) {
        setProd({})
        setFile(null)
        formRef.current.reset()
        props.onHide()
      } else {
        setError(res.message)
      }
    }).catch((e) => {
      setError(e.message)
    })
  }

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="h5" as="div">
          Add Product
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        { error && <Alert variant={'danger'}>{ error }</Alert>}

        <Form onSubmit={(e)=>onSave(e)}  ref={formRef}>
          <Form.Group controlId="name">
            <Form.Label>Product Name</Form.Label>
            <Form.Control defaultValue={prod.name} placeholder="Product Name" name="name"
              onChange={(e)=>onChange(e)} />
          </Form.Group>

          <Form.Group controlId="desc">
            <Form.Label>Product Description</Form.Label>
            <Form.Control as="textarea" rows="3" name="desc" defaultValue={prod.desc} 
              placeholder="Product Description" onChange={(e)=>onChange(e)} />
          </Form.Group>

          <Form.Group controlId="desc">
            <Form.Label>Product Picture</Form.Label>
            <div className="custom-file">
                <input name="src" type="file" onChange={(e) => setFile(e.target.files[0])} 
                  className="custom-file-input" id="file" />
                <label className="custom-file-label" htmlFor="file">
                  { file ? file.name : "Upload File" }
                </label>
            </div>
          </Form.Group>

          <Form.Group controlId="desc">
            <Form.Label>Product Category</Form.Label>
            <select id="desc" name="category_id" onChange={(e)=>{onChange(e)}} 
              className="form-control" >
              <option value={''}>Select Category</option>
              {
                categories !== null && categories.map((cat)=>(
                  <option key={cat._id} value={cat._id}>{cat.title}</option>
                ))
              }
            </select>
          </Form.Group>

          <Form.Group controlId="active">
            <Form.Label >Active Product</Form.Label>
            <input type="checkbox" defaultChecked={prod.active} style={{marginLeft: '10px'}}
              onClick={(e)=>onToggle(e)} />
            <Form.Text className="text-muted">   
              If checked, prod is immediately displayed on wastewaterdepot.com
            </Form.Text>
          </Form.Group>

          <Button variant="info" type="submit" block>
            Create Product
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  )
}

export default NewProduct