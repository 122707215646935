import Alert from 'react-bootstrap/Alert'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import React, { useState } from "react"
import { request, login } from '../lib/api'

function UserForm (props) {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [first_name, setfirst_name] = useState('')
  const [last_name, setlast_name] = useState('')
  const [error, setError] = useState(null)

  const onAdd = (data) => {
    setError(null)
    request('POST', '/user', data).then((e)=>{
      if (!e.success) {
        setError(e.message)
      } else {
        setError(null)
        login(e.user, e.token)
        props.onHide()
      }
    }).catch((e)=>{
      setError(e.message)
    })
  }
  
  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="h5" as="div">
          Add User
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        { error && <Alert variant={'danger'}>{ error }</Alert>}
        <Form>

          <Form.Group controlId="firstName">
            <Form.Label>First Name</Form.Label>
            <Form.Control type="text" placeholder="First Name" 
              onChange={(e)=>setfirst_name(e.target.value)} />
          </Form.Group>

          <Form.Group controlId="lastName">
            <Form.Label>Last Name</Form.Label>
            <Form.Control type="text" placeholder="Last Name" 
              onChange={(e)=>setlast_name(e.target.value)} />
          </Form.Group>

          <Form.Group controlId="formBasicEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control type="email" placeholder="Email" 
              onChange={(e)=>setEmail(e.target.value)} />
          </Form.Group>

          <Form.Group controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control onChange={(e)=>setPassword(e.target.value)} 
              type="password" placeholder="Password" />
          </Form.Group>

          <Button style={{ marginTop: '2rem' }} variant="info"
            onClick={() => onAdd({email, password, first_name, last_name})} block>
            Add User
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  )
}

export default UserForm